<template>
  <div>
    <v-dialog v-model="$store.state.actionDialog" width="800" persistent>
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title> {{ $t("alajraaat") }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form>
          <v-container fluid>
            <v-card outlined>
              <v-container fluid>
                <v-btn
                  @click="openAddDialog()"
                  large
                  color="primary"
                  class="btn"
                >
                  {{ $t("idhafh-ijraa") }}
                </v-btn>
                <v-divider class="mt-2" />
                <Loading v-if="$global.state.loading" />
                <v-data-table
                  v-if="!$global.state.loading"
                  class="mytable"
                  :items-per-page="$global.state.filter.pageSize"
                  :items="actions"
                  :loading="$global.state.loading"
                  :headers="headers"
                  hide-default-footer
                  :loading-text="$t('loading')"
                  :no-data-text="$t('no-data')"
                  :id="
                    $vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'
                  "
                >
                  <template v-slot:item.type="{ item }">
                    <v-chip v-if="item.type == 1">
                      {{ $t("intqal-ila") }}
                    </v-chip>
                    <v-chip color="error" v-if="item.type == 2">رفض</v-chip>
                    <v-chip color="teal" dark v-if="item.type == 3">{{
                      $t("inhaa-wiktmal")
                    }}</v-chip>
                    <v-chip color="teal" dark v-if="item.type == 4"
                      >{{ $t("qbwl") }}
                    </v-chip>
                  </template>

                  <template v-slot:item.actions="{ item, index }">
                    <v-tooltip bottom="">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="deleteItem(item.id, index)"
                          v-on="on"
                          icon
                        >
                          <v-icon color="error"> delete </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("hthf") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom="">
                      <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon>
                          <v-icon color="info"> edit </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("edit") }}</span>
                    </v-tooltip>
                  </template>

                </v-data-table>
              </v-container>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="actionDialog" width="400">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title>
            {{ actionDialog == "add" ? $t("idhafh") : $t("edit") }}
            إجراء</v-toolbar-title
          >
          <v-spacer />
          <v-btn @click="closeActionDialog()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form">
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("nwa-alijraa") }}<span class="required">*</span>
                    </h3>
                    <v-radio-group v-model="item.type">
                      <v-radio :value="1" :label="$t('intqal-ila')" />
                      <v-radio :value="2" :label="$t('rfdh')" />
                      <v-radio :value="3" :label="$t('inhaa-wiktmal')" />
                      <v-radio :value="4" :label="$t('qbwl')" />
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("ism-alijraa") }}<span class="required">*</span>
                    </h3>
                    <v-text-field
                      v-model="item.name"
                      :placeholder="$t('ism-alijraa')"
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="12" md="12" v-if="item.type == 1 || item.type == 4">
                    <h3 class="mb-2">
                      {{ $t("akhtr-alnwd-alreysyh")
                      }}<span class="required">*</span>
                    </h3>
                    <v-autocomplete
                      clearable
                      v-model="nextMainNodeId"
                      item-text="name"
                      item-value="id"
                      :items="$global.state.mainNodes"
                      class="mr-1"
                      append-icon=""
                      :placeholder="$t('alnwdat-alreysyh')"
                      :loading="$global.state.loading"
                      :no-data-text="$t('no-data')"
                      hide-details=""
                      outlined=""
                      filled=""
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  @click="closeActionDialog()"
                  x-large
                  color="error"
                  class="mr-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogType: "add",
      item: {},
      actionDialog: false,
      nextMainNodeId: "",
      headers: [
        {
          text: this.$t("ism-alijraa"),
          value: "name",
        },
        {
          text: this.$t("nwa-alijraa"),
          value: "type",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      actions: [],
    };
  },
  mounted() {
    this.reset();
    this.$eventBus.$on("fill-fields", () => {
      this.actions = [];
      this.getItems();
    });
  },
  methods: {
    reset() {
      this.item = {
        name: "",
        type: 0,
        // "mainNodeId": "",
        // "nextMainNodeId": ""
      };
      this.nextMainNodeId = "";
      this.mainNodeId = "";
    },
    async getItems() {
      // this.$global.dispatch(`getActions`, this.$store.state.itemDetails.id);
      this.$global.state.loading = true;
      try {
        const res = await this.$http.get(
          `Action?MainNodeId=${this.$store.state.itemDetails.id}&IsDeleted=false&PageIndex=1&PageSize=50`
        );
        this.actions = res.data.result;
        // this.state.length =
        //   res.data.count == undefined
        //     ? 0
        //     : Math.ceil(res.data.count / this.state.filter.pageSize);
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    async deleteItem(id, i) {
      await this.$service.deleteItem(id, `Action`, `delete`);

      await this.$eventBus.$once(`delete`, () => {
        //     this.getItems();
        //  this.actions.splice(i, 1);
        setTimeout(() => {
          this.getItems();
        }, 10);
      });
    },
    submit() {
      if (this.nextMainNodeId != "") {
        this.item.nextMainNodeId = this.nextMainNodeId;
      }
      this.item.mainNodeId = this.$store.state.itemDetails.id;
      if (this.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },
    async addItem() {
      this.$global.state.loading = true;
      try {
        await this.$http.post(`Action`, this.item);
        this.$service.success(this.$t("tm-alidhafh-bnjah"));
        await this.closeActionDialog();
        await this.getItems();
      } catch (err) {
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    editItem() {
      this.$global.state.loading = true;
      this.$http
        .put(`Action/${this.item.id}`, this.item)
        .then((res) => {
          this.closeActionDialog();
          this.$service.success(this.$t("tm-altadyl-bnjah"));
          this.getItems();
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },
    close() {
      this.$global.state.actions = [];
      // this.$eventBus.$off(`fill-fields`);
      this.$store.commit("setActionsDialog");
      // this.reset()
      // this.resetValidation()
      this.$store.commit("setItemDetails", {});
    },
    closeActionDialog() {
      this.reset();
      // this.resetValidation();
      this.actionDialog = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getSubNodes() {
      this.$global.dispatch(`getNodes`, this.$route.params.id);
    },

    openEditDialog(item) {
      console.log(item);
      this.dialogType = "edit";
      this.item = item;
      this.actionDialog = true;
    },

    openAddDialog() {
      this.dialogType = "add";
      this.actionDialog = true;
    },
  },
};
</script>
